// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-info-js": () => import("./../../../src/pages/legal-info.js" /* webpackChunkName: "component---src-pages-legal-info-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-usecases-cost-js": () => import("./../../../src/pages/usecases/cost.js" /* webpackChunkName: "component---src-pages-usecases-cost-js" */),
  "component---src-pages-usecases-demoenv-js": () => import("./../../../src/pages/usecases/demoenv.js" /* webpackChunkName: "component---src-pages-usecases-demoenv-js" */),
  "component---src-pages-usecases-demos-js": () => import("./../../../src/pages/usecases/demos.js" /* webpackChunkName: "component---src-pages-usecases-demos-js" */),
  "component---src-pages-usecases-testenv-js": () => import("./../../../src/pages/usecases/testenv.js" /* webpackChunkName: "component---src-pages-usecases-testenv-js" */),
  "component---src-pages-usecases-testmore-js": () => import("./../../../src/pages/usecases/testmore.js" /* webpackChunkName: "component---src-pages-usecases-testmore-js" */)
}

